:root {
  /* -----COLORS----- */
  /* Brand primaries */
  --dfx-purple: #180a3e;
  --dfx-teal: #00e8f1;
  --dfx-pink: #eb00d2;

  /* Variants */
  --pink-dark: #b800a5;

  /* Utility */
  --neutral-color: var(--dfx-purple);
  --primary-color: var(--dfx-pink);
  --primary-hover-color: var(--pink-dark);
  --primary-text-light: white;
  --secondary-text-light: rgba(255, 255, 255, 75%);
  --success-color: #198754;
  --error-color: #dc3545;
  --warning-color: #ffba49;
  --info-color: #17a2b8;
  --link-color: var(--dfx-teal);
  --frosted-glass: rgba(13, 19, 27, 0.7);
  --frosted-glass-dark: rgba(0, 0, 0, 0.5);

  /* -----FONTS----- */
  /* Use for text, headers, paragraphs */
  --text-font: "Roboto", sans-serif;
  /* Use for values like numbers, token symbols, addresses */
  --value-font: "IBM Plex Mono", monospace;
  --secondary-text-size: 0.875rem;

  /* -----OTHER----- */
  --rounded-box-border-radius: 10px;
  --link-text-shadow: 0 0 4px var(--link-color);
  --current-route-text-underline-offset: 10px;
  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

body {
  color: var(--primary-text-light);
  font-family: var(--text-font);
}
